<template>
  <v-dialog :value="value" persistent width="900px" height="500px" max-width="90vw" max-height="90vh">
    <v-card>
      <template v-if="isShuttling">
        <v-container class="pa-6">
          <v-progress-linear indeterminate height="25" rounded>Preparing to Shuttle...</v-progress-linear>
        </v-container>
      </template>
      <template v-else-if="currentView === 'list'">
        <v-card-title class="title">Shuttle Batch Changes</v-card-title>
        <v-container class="ma-0 pa-0">
          <v-data-table
            height="400"
            dense
            :items="adGroups"
            :headers="headers"
            :search="search"
            :loading="loading"
            :items-per-page="pageSize"
            hide-default-footer
            fixed-header
            item-key="party_id"
            show-select
            v-model="selectedAdGroups"
          >
            <template #top>
              <v-toolbar v-show="currentView === 'list'">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="Filter Parties"
                      prepend-inner-icon="mdi-filter-variant"
                      v-model="search"
                      dense
                      outlined
                      class="mt-4"
                    />
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-container>
        <v-toolbar flat>
          <span v-if="!permit" class="primary--text">Ad Groups must share the same distribution center and have a batch with the same TPR Date and Batch Type as the batch being shuttled from.</span>
          <v-spacer />
          <v-btn text @click.stop="$emit('update:value', false)">Cancel</v-btn>
          <v-btn
            class="ma-2"
            :loading="isShuttling"
            :disabled="!canShuttle"
            color="success"
            @click="showChanges"
          >Shuttle</v-btn>
        </v-toolbar>
      </template>
      <template v-else-if="currentView === 'changes'">
        <v-card-title v-if="commonChanges.length > 0" class="title">Changes to be Shuttled</v-card-title>
        <v-container v-if="commonChanges.length > 0" class="ma-0 pa-0">
          <v-data-table
            class="responsive-table"
            dense
            :items="commonChanges"
            :headers="changesHeaders"
            hide-default-footer
            fixed-header
            show-select
            item-key="itemId"
            v-model="selectedChanges"
          >
            <template #item.upc="{ item }"><td>{{ item.upc }}</td></template>
            <template #item.itemId="{ item }"><td>{{ item.itemId }}</td></template>
            <template #item.itemDescription="{ item }"><td :class="highlightClass(item, 'itemDescription')">{{ item.itemDescription }}</td></template>
            <template #item.multiplier="{ item }"><td :class="highlightClass(item, 'multiplier')">{{ item.multiplier }}</td></template>
            <template #item.srp="{ item }"><td :class="highlightClass(item, 'srp')">{{ formatCurrency(item.srp) }}</td></template>
          </v-data-table>
        </v-container>
        <v-card-title v-if="nonCommonChanges.length > 0" class="title">Changes that cannot be shuttled to one or more ad groups</v-card-title>
        <v-container v-if="nonCommonChanges.length > 0" class="ma-0 pa-0">
          <v-data-table
            class="responsive-table"
            dense
            :items="nonCommonChanges"
            :headers="nonCommonChangesHeaders"
            hide-default-footer
            fixed-header
            disable-pagination
            disable-sort
            disable-filter
            item-key="itemId"
          >
            <template #item="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <tr v-on="on">
                    <td>{{ item.upc }}</td>
                    <td>{{ item.itemId }}</td>
                    <td>{{ item.itemDescription }}</td>
                    <td>{{ item.multiplier }}</td>
                    <td>{{ formatCurrency(item.srp) }}</td>
                  </tr>
                </template>
                <span v-if="item.missingAdGroups && item.missingAdGroups.length > 0">Missing in: {{ item.missingAdGroups.join(', ') }}</span>
                <span v-else>No missing ad groups</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-container>
        <v-toolbar flat>
          <v-spacer />
          <v-btn text @click.stop="currentView = 'list'">Back</v-btn>
          <v-btn
            class="ma-2"
            :loading="isShuttling"
            :disabled="selectedChanges.length === 0"
            color="success"
            @click="saveChanges"
          >Confirm Shuttle</v-btn>
        </v-toolbar>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import Batches from '@/axios/batches';
import Items from '@/axios/items';
import Rules from '@/axios/rules-management.js';
import { userAccess } from '@/mixins/user-access'

export default {
  name: 'AdGroupShuttle',
  mixins: [userAccess],
  props: {
    value: { type: Boolean, default: false },
    ad_group: { type: Object, default: () => ({}) },
    batch: { type: Object, default: () => ({}) },
    batch_changes: { type: Array, default: () => [] },
  },
  data() {
    return {
      search: '',
      commonChanges: [],
      nonCommonChanges: [],
      selectedAdGroups: [],
      selectedChanges: [],
      adGroups: [],
      loading: false,
      isShuttling: false,
      permit: false,
      pageSize: 100,
      currentView: 'list',
      changesHeaders: [
        { text: '', value: 'data-table-select' },
        { text: 'UPC', value: 'upc' },
        { text: 'Item ID', value: 'itemId' },
        { text: 'Description', value: 'itemDescription' },
        { text: 'Multiplier', value: 'multiplier' },
        { text: 'SRP', value: 'srp' },
      ],
      nonCommonChangesHeaders: [
        { text: 'UPC', value: 'upc' },
        { text: 'Item ID', value: 'itemId' },
        { text: 'Description', value: 'itemDescription' },
        { text: 'Multiplier', value: 'multiplier' },
        { text: 'SRP', value: 'srp' },
      ],
      batch_row: {},
    };
  },
  computed: {
    headers() {
      return [{ text: 'Ad Groups', sortable: true, filterable: true, value: 'party_name' }];
    },
    canShuttle() {
      return this.selectedAdGroups.length > 0;
    },
    allSelected() {
      return this.commonChanges.length > 0 && this.selectedChanges.length === this.commonChanges.length;
    },
  },
  async created() {
    if (this.value) this.getAdGroups();
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) this.getAdGroups();
      },
    },
  },
  methods: {
    async getAdGroups() {
      this.loading = true;
      this.selectedAdGroups = [];
      try {
        // Fetch all ad groups from the API
        const response = await Batches.getShuttleAdGroups(this.ad_group.id, this.batch.tpr_date, this.batch.batch_type);
        const allAdGroups = response.data;

        // Filter the ad groups to only include those in userAdGroups
        const userAdGroupIds = this.userAdGroups.map(adGroup => adGroup.id);
        this.adGroups = allAdGroups
          .filter(adGroup => userAdGroupIds.includes(adGroup.party_id)) // Filter by userAdGroups
          .sort((a, b) => a.party_name.localeCompare(b.party_name)); // Sort by name

        this.loading = false;
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          status: 'error',
          text: `Failed to Get Ad Group List due to ${err.message}`,
        });
        this.loading = false;
      }
    },
    async showChanges() {
      if (this.batch_changes.length === 0) {
        this.$store.dispatch('setSnackbar', { status: 'info', text: 'There are no saved changes to shuttle.' });
        return;
      }

      this.selectedChanges = []
      const ad_groups = this.selectedAdGroups.map(item => item.party_id);
      const itemIds = this.batch_changes.map(item => String(item.itemId));

      try {
        const res = await Batches.getShuttleAdGroupsWithShuttleItems(ad_groups, this.batch.tpr_date, itemIds, this.batch.batch_type);

        const itemIdToBatchRows = {};
        res.data.forEach(row => {
          const itemId = row.item_id;
          if (!itemIdToBatchRows[itemId]) itemIdToBatchRows[itemId] = [];
          itemIdToBatchRows[itemId].push(row.batch_item);
        });

        this.batch_rows = itemIdToBatchRows;
        const groupedByItem = {};
        res.data.forEach(row => {
          if (!groupedByItem[row.item_id]) groupedByItem[row.item_id] = new Set();
          groupedByItem[row.item_id].add(row.ad_grp_id);
        });

        const totalAdGroups = ad_groups.length;
        const commonChanges = [];
        const nonCommonChanges = [];

        for (const itemId of itemIds) {
          if (groupedByItem[itemId] && groupedByItem[itemId].size === totalAdGroups) {
            const change = this.batch_changes.find(change => change.itemId === itemId);
            if (change) commonChanges.push(change);
          } else {
            const change = this.batch_changes.find(change => change.itemId === itemId);
            if (change) {
              const missingAdGroups = this.selectedAdGroups
                .filter(adGroup => !groupedByItem[itemId] || !groupedByItem[itemId].has(adGroup.party_id))
                .map(adGroup => adGroup.party_name);
              change.missingAdGroups = missingAdGroups;
              nonCommonChanges.push(change);
            }
          }
        }

        this.commonChanges = commonChanges;
        this.nonCommonChanges = nonCommonChanges;

        this.currentView = 'changes';
      } catch (err) {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to retrieve shuttle items: ${err.message}` });
      }
    },
    highlightClass(item, field) {
      return item.changedColumns && item.changedColumns.includes(field) ? "highlight" : "";
    },
    formatCurrency(value) {
      if (value == null || value === '') return '$0.00';

      const numericValue = parseFloat(value);

      if (isNaN(numericValue)) return '$0.00';

      if (Number.isInteger(numericValue)) {
        return `$${numericValue.toFixed(2)}`;
      }

      return `$${numericValue.toFixed(2)}`;
    },
    async saveChanges() {
      this.isShuttling = true;
      const payloads = [];
      for (const change of this.selectedChanges) {
        const batchRows = this.batch_rows[change.itemId] || [];
        for (const batchRow of batchRows) {
          const item = this.batch_changes.find(i => i.itemId === change.itemId);
          if (item) {
            const payload = { id: batchRow, promotion_price: item.srp, multiplier: item.multiplier, note: item.note };
            payloads.push(payload);
          }
        }
      }

      if (payloads.length > 0 && !this.isTprProRetailUser) {
        try {
          await Items.tprUpdate(payloads);
          this.$store.dispatch('setSnackbar', { status: 'success', text: 'Changes shuttled successfully' });
        } catch (err) {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Error updating items: ${err.message}` });
        }
      } else if (this.isTprProRetailUser) {
        try {
          await Rules.storeSrpMultiplier(payloads);
          this.$store.dispatch('setSnackbar', { status: 'success', text: 'Item(s) shuttled successfully' });
        } catch (err) {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Error updating store items: ${err.message}` });
        }
      }

      this.isShuttling = false;
      this.currentView = 'list';
      this.commonChanges = []
      this.nonCommonChanges = []
      this.selectedAdGroups =  []
      this.selectedChanges = []
      this.$emit("update:value", false);
    },
  },
};
</script>

<style scoped>
.highlight { background-color: #ffeb3b !important; }
.responsive-table { max-width: 100%; overflow-x: auto; }
.disabled-table { opacity: 0.6; pointer-events: none; }
</style>