<template>
  <v-dialog :value="value" persistent width="900">
    <v-card>
      <v-container class="ma-0 pa-0" fluid>
        <v-data-table
          :headers="headers"
          :height="tableSize"
          :loading="loading"
          :items="items"
          :items-per-page="-1"
          dense
          fixed-header
          hide-default-footer
          no-data-text="No Items to Display"
        >
          <template #top>
            <v-toolbar class="main" dark flat>
              <v-toolbar-title>
                {{ selectedItem.description }}
              </v-toolbar-title>
              <v-spacer/>
              <v-btn icon @click.stop="$emit('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
           </template>
          <template #[`item.item_id`]="{ item }">
            <span v-if="item.item_id">
              {{item.item_id}}
            </span>
            <span v-else>-</span>
          </template>
          <template #[`item.item_desc`]="{ item }">
            <span v-if="item.item_desc">
              {{item.item_desc}}
            </span>
            <span v-else>-</span>
          </template>
          <template #[`item.upc`]="{ item }">
            <span v-if="item.upc">
              {{item.upc}}
            </span>
            <span v-else>-</span>
          </template>
          <template #[`item.qty_per_case`]="{ item }">
              <span v-if="item.qty_per_case">
                {{item.qty_per_case}}
              </span>
              <span v-else>-</span>
          </template>
          <template #[`item.pack_size`]="{ item }">
              <span v-if="item.pack_size">
                {{item.pack_size}}
              </span>
              <span v-else>-</span>
          </template>
          <template #[`item.qty_per_shipper`]="{ item }">
              <span v-if="item.qty_per_shipper">
                {{item.qty_per_shipper}}
              </span>
              <span v-else>-</span>
          </template>
          <template #[`item.last_90_days_shipped_qty`]="{ item }">
              <span v-if="item.last_90_days_shipped_qty != null">
                {{item.last_90_days_shipped_qty}}
              </span>
              <span v-else>-</span>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { fullwidth } from "@/mixins/fullwidth"
import { displayHelpers } from "@/mixins/displayHelpers"
import { utils } from '@/mixins/utils'
import Items from '@/axios/items.js'
import Rules from '@/axios/rules-management.js'
export default {
  data() {
    return {
      loading: false,
      items: [],
      headers: [
        { class: 'accent', sortable: false },
        { text: 'Item Id', class: 'accent white--text', sortable: true, filterable: true, value: 'item_id', width: '100' },
        { text: 'Description', class: 'accent white--text', sortable: true, filterable: true, value: 'item_desc', width: 'auto' },
        { text: 'UPC', class: 'accent white--text', sortable: true, filterable: true, value: 'upc', width: '145' },
        { text: 'Qty', class: 'accent white--text', sortable: true, filterable: true, value: 'qty_per_case', width: '75' },
        { text: 'Pack Size', class: 'accent white--text', sortable: true, filterable: true, value: 'pack_size', width: '75' },
        { text: 'Qty Per Shipper', align: 'center', class: 'accent white--text', sortable: true, filterable: true, value: 'qty_per_shipper', width: '135' },
        { text: 'Avg. Movement', align: 'center', sortable: true, filterable: true, class: 'accent white--text', value: 'last_90_days_shipped_qty', width: '135' },
      ]
    }
  },
  name: 'ComponentItems',
  mixins: [fullwidth, displayHelpers, utils],
  props: {
    value: Boolean,
    selectedItem: Object,
    selectedBatch: Object,
    isTprProRetailUser: Boolean,
  },
  created() {
    this.initComponentItems()
  },
  methods: {
    async initComponentItems() {
      this.loading = true
      try {
        if (!this.isTprProRetailUser) {
          const res = await Items.getComponents(
            this.selectedBatch.id,
            this.selectedItem.item_id,
            this.pageSize,
            this.from
          )
          this.items = res?.data || []
        } else {
          const res = await Rules.getComponents(
            this.selectedBatch.tpr_batch_id,
            this.selectedItem.item_id,
            this.pageSize,
            this.from
          )
          this.items = res?.data || []
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.noWrap {
  white-space: nowrap;
}
</style>
