var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.value,
        persistent: "",
        width: "900px",
        height: "500px",
        "max-width": "90vw",
        "max-height": "90vh",
      },
    },
    [
      _c(
        "v-card",
        [
          _vm.isShuttling
            ? [
                _c(
                  "v-container",
                  { staticClass: "pa-6" },
                  [
                    _c(
                      "v-progress-linear",
                      {
                        attrs: { indeterminate: "", height: "25", rounded: "" },
                      },
                      [_vm._v("Preparing to Shuttle...")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm.currentView === "list"
            ? [
                _c("v-card-title", { staticClass: "title" }, [
                  _vm._v("Shuttle Batch Changes"),
                ]),
                _c(
                  "v-container",
                  { staticClass: "ma-0 pa-0" },
                  [
                    _c("v-data-table", {
                      attrs: {
                        height: "400",
                        dense: "",
                        items: _vm.adGroups,
                        headers: _vm.headers,
                        search: _vm.search,
                        loading: _vm.loading,
                        "items-per-page": _vm.pageSize,
                        "hide-default-footer": "",
                        "fixed-header": "",
                        "item-key": "party_id",
                        "show-select": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "top",
                          fn: function () {
                            return [
                              _c(
                                "v-toolbar",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.currentView === "list",
                                      expression: "currentView === 'list'",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mt-4",
                                            attrs: {
                                              label: "Filter Parties",
                                              "prepend-inner-icon":
                                                "mdi-filter-variant",
                                              dense: "",
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.search,
                                              callback: function ($$v) {
                                                _vm.search = $$v
                                              },
                                              expression: "search",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.selectedAdGroups,
                        callback: function ($$v) {
                          _vm.selectedAdGroups = $$v
                        },
                        expression: "selectedAdGroups",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    !_vm.permit
                      ? _c("span", { staticClass: "primary--text" }, [
                          _vm._v(
                            "Ad Groups must share the same distribution center and have a batch with the same TPR Date and Batch Type as the batch being shuttled from."
                          ),
                        ])
                      : _vm._e(),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$emit("update:value", false)
                          },
                        },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ma-2",
                        attrs: {
                          loading: _vm.isShuttling,
                          disabled: !_vm.canShuttle,
                          color: "success",
                        },
                        on: { click: _vm.showChanges },
                      },
                      [_vm._v("Shuttle")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm.currentView === "changes"
            ? [
                _vm.commonChanges.length > 0
                  ? _c("v-card-title", { staticClass: "title" }, [
                      _vm._v("Changes to be Shuttled"),
                    ])
                  : _vm._e(),
                _vm.commonChanges.length > 0
                  ? _c(
                      "v-container",
                      { staticClass: "ma-0 pa-0" },
                      [
                        _c("v-data-table", {
                          staticClass: "responsive-table",
                          attrs: {
                            dense: "",
                            items: _vm.commonChanges,
                            headers: _vm.changesHeaders,
                            "hide-default-footer": "",
                            "fixed-header": "",
                            "show-select": "",
                            "item-key": "itemId",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item.upc",
                                fn: function ({ item }) {
                                  return [_c("td", [_vm._v(_vm._s(item.upc))])]
                                },
                              },
                              {
                                key: "item.itemId",
                                fn: function ({ item }) {
                                  return [
                                    _c("td", [_vm._v(_vm._s(item.itemId))]),
                                  ]
                                },
                              },
                              {
                                key: "item.itemDescription",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "td",
                                      {
                                        class: _vm.highlightClass(
                                          item,
                                          "itemDescription"
                                        ),
                                      },
                                      [_vm._v(_vm._s(item.itemDescription))]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.multiplier",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "td",
                                      {
                                        class: _vm.highlightClass(
                                          item,
                                          "multiplier"
                                        ),
                                      },
                                      [_vm._v(_vm._s(item.multiplier))]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.srp",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "td",
                                      {
                                        class: _vm.highlightClass(item, "srp"),
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.formatCurrency(item.srp))
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4003834364
                          ),
                          model: {
                            value: _vm.selectedChanges,
                            callback: function ($$v) {
                              _vm.selectedChanges = $$v
                            },
                            expression: "selectedChanges",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.nonCommonChanges.length > 0
                  ? _c("v-card-title", { staticClass: "title" }, [
                      _vm._v(
                        "Changes that cannot be shuttled to one or more ad groups"
                      ),
                    ])
                  : _vm._e(),
                _vm.nonCommonChanges.length > 0
                  ? _c(
                      "v-container",
                      { staticClass: "ma-0 pa-0" },
                      [
                        _c("v-data-table", {
                          staticClass: "responsive-table",
                          attrs: {
                            dense: "",
                            items: _vm.nonCommonChanges,
                            headers: _vm.nonCommonChangesHeaders,
                            "hide-default-footer": "",
                            "fixed-header": "",
                            "disable-pagination": "",
                            "disable-sort": "",
                            "disable-filter": "",
                            "item-key": "itemId",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c("tr", _vm._g({}, on), [
                                                    _c("td", [
                                                      _vm._v(_vm._s(item.upc)),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(item.itemId)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.itemDescription
                                                        )
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(item.multiplier)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatCurrency(
                                                            item.srp
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        item.missingAdGroups &&
                                        item.missingAdGroups.length > 0
                                          ? _c("span", [
                                              _vm._v(
                                                "Missing in: " +
                                                  _vm._s(
                                                    item.missingAdGroups.join(
                                                      ", "
                                                    )
                                                  )
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v("No missing ad groups"),
                                            ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1752968904
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.currentView = "list"
                          },
                        },
                      },
                      [_vm._v("Back")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ma-2",
                        attrs: {
                          loading: _vm.isShuttling,
                          disabled: _vm.selectedChanges.length === 0,
                          color: "success",
                        },
                        on: { click: _vm.saveChanges },
                      },
                      [_vm._v("Confirm Shuttle")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }