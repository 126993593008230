<template>
    <v-menu
      :close-on-content-click="false"
      :nudge-bottom="3"
      :nudge-left="75"
      :value="uploadMenu"
      bottom
      offset-y>
      <template #activator="{ on: menu, attrs }">
        <v-tooltip top>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              @click="uploadMenu = true"
              small
              class="grey lighten-2 ml-2"
              height="38"
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }">
              <v-icon>mdi-folder-upload-outline</v-icon>
            </v-btn>
          </template>
          <span>TPR SRP Upload</span>
        </v-tooltip>
      </template>
      <v-card class="fill" min-width="450" max-width="700">
        <v-card-title class="pb-0 text-subtitle-1"> New TPR SRP Upload </v-card-title>
        <v-container class="pt-0 pb-2 px-6">
          <v-row dense>
            <v-file-input class="mt-4" outlined dense background-color="#fff" v-model="file" prepend-inner-icon="mdi-paperclip" prepend-icon=""
              accept=".csv" chips label="Select File for Upload" truncate-length="25" show-size 
              :rules="[v => !!v || 'File is Required']" @change="handleFile">
            </v-file-input>
          </v-row>
          <v-row dense>
            <v-autocomplete outlined dense background-color="white" label="Base Ad Group" :items="adGroups"
              item-text="name" return-object v-model="baseGroup" :menu-props="{ maxHeight: 215 }"
              :rules="[v => !!v || 'Base Ad Group is Required']" @change="changeBaseGroup"/>
          </v-row>
        </v-container>
        <v-container class="ma-0 pt-0 pr-0 pl-4" fluid>
          <v-list class="pt-0" style="height: 300px; overflow:auto;" color="white" :loading="loading">
            <v-list-item-group v-model="selectedAdGroups" active-class="primary--text" multiple>
              <template v-for="(item, index) in dcMatchedAdGroups">
                <v-list-item :key="item.id" :value="item.id" dense>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{item.name}}
                    </v-list-item-title>
                  </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="green">{{selectedAdGroups.includes(item.id) ? 'mdi-check' : ''}}</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-divider v-if="index < dcMatchedAdGroups.length - 1" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-container>
        <v-card-actions class="fill">
          <v-spacer />
          <v-btn :class="body" text @click="uploadMenu = false"> Cancel </v-btn>
          <v-btn :class="body" :loading="loading" :disabled="!hasRequiredFields" color="success" class="green white--text" @click="uploadFile">
            Upload <v-icon right>mdi-file-upload-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </template>
  
  <script>
  import Items from '@/axios/items'
  import Parties from '@/axios/parties'
  import { displayHelpers } from '@/mixins/displayHelpers'
  import { shared } from '@/mixins/shared'
  
  export default {
    name: 'TprSrpUpload',
    props: ['baseAdGroup'],
    mixins: [displayHelpers, shared],
    data () {
      return {
        uploadMenu: false,
        file: null,
        baseGroup: null,
        loading: false,
        selectedAdGroups: [],
        adGroupsWithMatchingDC: []
      }
    },
    async created () {
      this.baseGroup = this.baseAdGroup
      this.selectedAdGroups.push(this.baseGroup.id)
    },
    computed: {
      adGroups () {
        const localAdGroups = this.$store.getters.adGroups
        return localAdGroups.sort((a, b) => a.name.localeCompare(b.name))
      },
      dcMatchedAdGroups () {
        return this.adGroups.filter(adGroup => this.adGroupsWithMatchingDC.includes(adGroup.id))
      },
      hasRequiredFields () {
        return this.file && this.baseGroup && this.selectedAdGroups.includes(this.baseGroup.id)
      }
    },
    watch: {
      baseAdGroup: {
        handler(newValue) {
          this.baseGroup = newValue
        }
      },
      baseGroup: {
        async handler(newValue) {
          this.changeBaseGroup(newValue)
          await this.lookupDCs(newValue)
        }
      },
      adGroups: {
        async handler(newValue) {
          if (newValue?.length > 0) {
            await this.lookupDCs(this.baseGroup)
          }
        }
      }
    },
    methods: {
      handleFile(file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.file.upload_file = e.target.result
        }
        reader.readAsArrayBuffer(file)
      },
      async lookupDCs (adGroup) {
        if (!adGroup) return;  // Guard clause to avoid processing a null adGroup
        try {
          if (this.adGroups.length > 0) {
            const adGroupIds = this.adGroups.map(adGroup => adGroup.id)
            const downstreamSubvendorRes = await Parties.upstreamRelationshipSearch({ from_party_ids: adGroupIds })
            const dcRelationships = downstreamSubvendorRes.data.filter(rel => rel.from_rel_type === 'AD_GROUP_HAS_PARENT')
            const baseAdGroupDc = dcRelationships.find(rel => rel.from_party_id === adGroup.id)
            const baseAdGroupDcId = baseAdGroupDc?.to_party_id
            if (baseAdGroupDcId) {
              this.adGroupsWithMatchingDC = dcRelationships.filter(rel => rel.to_party_id === baseAdGroupDcId).map(rel => rel.from_party_id)
            } else {
              this.adGroupsWithMatchingDC = [adGroup.id]
            }
          }
        } catch (e) {
          this.alert(`DC lookup failed: ${e}`)
        }
      },
      async uploadFile () {
        this.loading = true
        try {
          await this.processFile(this.file)
          this.notify('File successfully submitted for processing.')
        } catch (e) {
          this.alert(`Upload attempt failed: ${e}`)
        }
        this.loading = false
        this.uploadMenu = false
      },
      async processFile(file) {
        const blob = new Blob([file.upload_file], { type: `${file.type}` })
        const formData = new FormData()
        const today = (new Date()).toISOString().split('T')[0]
        formData.append('path', `tpr-srp/${this.tenant}/uploads/${today}/`)
        formData.append('name', `${file.name}`)
        formData.append('upload_file', blob, `${file.name}`)
        return Items.uploadTprSrp(formData, this.selectedAdGroups.toString())
      },
      async changeBaseGroup (adGroup) {
        this.selectedAdGroups = []
        if (adGroup)  {
          this.selectedAdGroups.push(adGroup.id)
        }
      },
    }
  }
  </script>
  