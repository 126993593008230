import Batches from '@/axios/batches'
import Rules from '@/axios/rules-management.js'
import Documents from '@/axios/documents'
import AlertDialog from '@/components/AlertDialog'
import EditNameDialog from '@/components/EditNameDialog'
import DeleteBatchDialog from '@/components/DeleteBatchDialog.vue'
import { userAccess } from '@/mixins/user-access'
import { notification } from '@/mixins/notification'
import AdGroupShuttle from '@/components/AdGroupShuttle.vue'


export const shared = {
  components: { AlertDialog, EditNameDialog, DeleteBatchDialog, AdGroupShuttle},
  mixins: [userAccess, notification],
  data() {
    return {
      adGroupId: '',
      adGroupName: '',
      adShuttleDialog: false,
      alertDialog: false,
      editNameDialog: false,
      deleteBatchDialog: false,
      exporting: false,
      selectedBatch: {},
      total: '',
      isSyncing: false
    }
  },
  computed: {
    tprProTenants(){
      return this.$auth.tenant === 'alliance-retail-group'
    },
    isTprProRetailUser() {
      return this.userGroups.includes('tpr-manager.pro')
    },
     isBatchAvailable() {
      return ['COMPLETE', 'PUBLISHED', 'EXCEPTION', 'Exception']
    },
    actions() {
      if (['Exception', 'EXCEPTION'].includes(this.selectedBatch?.status)) {
        return [
          { text: 'Sync', icon: 'mdi-sync', method: this.versionCheck, show: true },
          { text: 'Delete', icon: 'mdi-trash-can', method: this.deleteBatch, show: this.$auth.tenant === 'pricechopper' },
        ].filter(item => item.show)
      }
      if (this.$auth.tenant == 'pricechopper') {
        return [
          { text: 'Edit', icon: 'mdi-pencil', method: this.updateBatchName },
          { text: 'Delete', icon: 'mdi-trash-can', method: this.deleteBatch },
          { text: 'Export', icon: 'mdi-microsoft-excel', method: this.batchExport },
          { text: 'Sync', icon: 'mdi-sync', method: this.versionCheck },
          { text: 'Send to Partner Portal', icon: 'mdi-file-upload', method: this.documentUpload }
        ]
      }
      if (this.$auth.tenant == 'alliance-retail-group') {
        if (this.isTprProRetailUser) {
          return [
            { text: 'Sync', icon: 'mdi-sync', method: this.versionCheck }
          ]
        } 
        if (!['PUBLISHED'].includes(this.selectedBatch?.status)) {
          const menuItems = [
            { text: 'Export', icon: 'mdi-microsoft-excel', method: this.batchExport },
            { text: 'Sync', icon: 'mdi-sync', method: this.versionCheck },
            { text: 'Publish', icon: 'mdi-publish', method: this.batchPublish }
          ];
        
          // Add "Shuttle" only if the route is 'BatchView'
          if (this.$route.name === 'BatchView') {
            menuItems.push({ text: 'Shuttle', icon: 'mdi-bus-multiple', method: this.shuttleChanges });
          }
        
          return menuItems;
        }
        else {
          return [
            { text: 'Export', icon: 'mdi-microsoft-excel', method: this.batchExport }
          ]
        }
      }
      else {
        if (!['PUBLISHED'].includes(this.selectedBatch?.status)) {
          return [
            { text: 'Export', icon: 'mdi-microsoft-excel', method: this.batchExport },
            { text: 'Sync', icon: 'mdi-sync', method: this.versionCheck },
            { text: 'Publish', icon: 'mdi-publish', method: this.batchPublish }
          ]
        } else {
          return [
            { text: 'Export', icon: 'mdi-microsoft-excel', method: this.batchExport }
          ]
        }
      }
    }
  },
  watch: {
    selectedBatch: {
      handler(newValue) {
        if (newValue) {
          sessionStorage.setItem(
            'tpr_manager_selected_batch',
            JSON.stringify(this.selectedBatch)
          )
        }
      },
      deep: true
    }
  },
  methods: {
    async versionCheck(batch) {
      this.selectedBatch = batch
      try {
        if(!this.isTprProRetailUser){
          const { data } = await Batches.versionCheck(
            batch.ad_group_id,
            batch.tpr_date
          )
          if (data.total > 0) {
            this.total = data.total.toString()
            this.alertDialog = true
          } else {
            this.batchUpdate(batch)
          }
        }
        else {
          const res = await Rules.syncStoreBatch(batch.tpr_batch_id, batch.store_id)
          if (res.status === 200) {
            if (this.$route.name === 'BatchView') {
              this.$router.push({ name: 'BatchList' })
            } else if (this.$route.name === 'BatchList') {
              this.getStoreBatches()
            }
            this.notify('Batch Sync Submitted Successfully')
          }
        }
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`Unexpected Error: ${message}`)
      }
    },
    async batchUpdate(batch) {
      this.isSyncing = true
      try {
        await Batches.batchUpdate(
          batch.ad_group_id,
          batch.tpr_date,
          batch.id,
          batch.tpr_end_date
        )
        this.notify('Batch Synced Successfully')
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`Error Syncing Batch: ${message}`)
      } finally {
        this.isSyncing = false
        if (this.$route.path === '/view-batch') {         
          this.$router.push({ path: '/'})
        } else {
          this.getBatches()
        }       
      }
    },    
    async batchPublish(batch) {
      try {
        if (this.$route.name === 'BatchView')
          this.$router.push({ name: 'BatchList' })
        if(this.tprProTenants){
          await Rules.publishTprReport(batch.id)
          await Batches.updateBatchStatus(batch.id, 'PUBLISHED')
        } else {
          await Batches.batchPublish(batch.id)
        }
        this.notify('Batch Submitted Successfully')
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`Error Submitting Batch: ${message}`)
      } finally {
        this.getBatches()
      }
    },
    async shuttleChanges() {
      try {
        this.$root.$emit("shuttleWholeBatch");
      } catch (err) {
        console.error(err);
        const message = err?.response?.data?.message || err.message;
        this.alert(`Error Shuttling Batch: ${message}`);
      }
    },     
    updateBatchName(batch){
      this.selectedBatch = batch
      this.editNameDialog = true
    },
    deleteBatch(batch){
      this.selectedBatch = batch
      this.deleteBatchDialog = true
    },
    async batchExport(batch) {
      this.actionMenu = false
      this.exporting = true
      const userEmail = this.userEmail
      const allUserHeaders = JSON.parse(localStorage.getItem("userHeaders")) || {}
      let selectedHeaders
    
      if (allUserHeaders[userEmail]) {
        selectedHeaders = allUserHeaders[userEmail]
      } else {
        selectedHeaders = this.allHeaders.map(header => header.value)
      }
      try {
        const { data } = await Batches.exportBatch(
          batch.id,
          batch.batch_name,
          this.adGroup ? this.adGroup.name : this.adGroupName,
          batch.tpr_date,
          batch.tpr_end_date,
          selectedHeaders
        )
        var anchor = document.createElement('a')
        anchor.href = data
        anchor.download = `${batch.batch_name}`
        anchor.click()
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`Error Exporting Batch: ${message}`)
      } finally {
        this.exporting = false
      }
    },
    async documentUpload(batch) {
      const searchTags = {
        "category": "RETAILER_REPORT",
        "subcategory": "RETAILER_TPR_REPORT",
        "batch_id":`${batch.id}`
      }
      try {
        const res = await Documents.searchDoc(searchTags)
        if (res.data?.length > 0){
            await Documents.deleteDoc(res.data[0].id)
        }
      } catch(err) {
        this.handleError(err)
      } 
      this.actionMenu = false
      this.exporting = true
      let ad_group = this.adGroup ? this.adGroup.name : this.selectedAdGroup.name
      var todayDate = new Date().toISOString().slice(0, 10);
      let docName = `${batch.tpr_date} ${batch.batch_name} TPR Report`
      let fileName = `TPR_Export_${ad_group}_${batch.tpr_date}.csv`
      let description = `TPR Batch Export for ${ad_group} for ${batch.tpr_date}`
      if (this.$auth.tenant == 'pricechopper') {
        docName = batch.batch_name
        fileName = `${batch.batch_name}.csv`
        description = `TPR Batch Export for ${ad_group} from ${batch.tpr_date} to ${batch.tpr_end_date}`
      }
      const docPayload = {
        "document": {
          "name": docName,
          "description": description,
          "root_key": "ipro_portal",
          "tags": {
            "status": "CURRENT",
            "category": "RETAILER_REPORT",
            "subcategory": "RETAILER_TPR_REPORT",
            "batch_id":`${batch.id}`
          },
        },
        "document_file": {
            "path": `tpr/${batch.id}`,
            "name": fileName,
            "mime_type": "text/csv"
        },
          "source_bucket": process.env.VUE_APP_S3_BUCKET,
          "source_path": `tpr/exports/${todayDate}/${fileName}`
        }
      try {
        await Batches.exportBatch(
          batch.id,
          batch.batch_name,
          ad_group,
          batch.tpr_date,
          batch.tpr_end_date
        )
        const document_resp = await Documents.registerDocument(
          docPayload
        )
        let document_id = document_resp.data.id 
        await Documents.registerDocParty(
          batch.ad_group_id,
          document_id
        )
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`Error Uploading Batch: ${message}`)
      } finally {
        this.exporting = false
      }
    },
    closeEditNameModal() {
      this.editNameDialog = false
    },
    closeDeleteBatchModal() {
      this.deleteBatchDialog = false
    } 
  }
}